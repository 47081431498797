export default {
  "common": {
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση για κάτι που σας αρέσει ..."])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η σελίδα δεν βρέθηκε"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση για κάτι"])}
  },
  "footer": {
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting SAGL"])},
    "company_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Vedeggio 4 - 6928 Manno (Switzerland) - Partita IVA CHE- 309.820.548 IVA"])},
    "company_payoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πάντα προσφέρει αξιόπιστες συγκρίσεις προϊόντων"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα τα δικαιώματα διατηρούνται"])},
    "cookiePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πολιτική cookies"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πολιτική απορρήτου"])},
    "cookieSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις cookies"])},
    "for_advertisers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])}
  },
  "search": {
    "sponsored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsored content"])},
    "related_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προτεινόμενες αναζητήσεις"])},
    "ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πήγαινε"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποτελέσματα για:"])},
    "results_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["βρέθηκαν αποτελέσματα"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αποστολή"])},
    "freeshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δωρεάν αποστολή"])},
    "go_to_shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πήγαινε στο κατάστημα"])},
    "try_searching_something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσπαθήστε να αναζητήσετε κάτι"])},
    "no_element_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν στοιχεία"])},
    "searchLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρτωση..."])},
    "something_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάτι πήγε στραβά"])},
    "sort": {
      "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ταξινόμηση κατά:"])},
      "relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχετικότητα"])},
      "priceAscending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αύξηση τιμής"])},
      "priceDescending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η τιμή μειώνεται"])}
    }
  },
  "cookie": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept all"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απορρίψτε όλα"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πολιτική απορρήτου"])},
    "bannerText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αυτός ο ιστότοπος χρησιμοποιεί cookies για να επιτρέπει στον ιστότοπο να λειτουργεί και να παρακολουθεί τις αλληλεπιδράσεις σας με τον ιστότοπο. Για να μας επιτρέψετε να σας παρέχουμε την καλύτερη δυνατή εμπειρία χρήστη, αποδεχτείτε όλα τα cookies. Για να μάθετε περισσότερα σχετικά με τα προσωπικά δεδομένα που συλλέγουμε και πώς χρησιμοποιούνται τα cookies, ελέγξτε την ", _interpolate(_named("privacyPolicy")), " μας."])}
  },
  "news": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "advertisers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])},
    "lastUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last Updated: ", _interpolate(_named("last_updated_date"))])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting"])},
    "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traffic to this site is generated through ", _interpolate(_named("company_name")), "'s proprietary technology, which allows us to place native ads with targeted keywords on multiple platforms such as Outbrain, Taboola, and others, which then lead to our various sites where search ads are served."])},
    "additionalInquiries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For any additional inquiries,\nEmail: ", _interpolate(_named("company_email"))])}
  }
}