<template>
  <div
    class="h-full bg-base-100 z-50 fixed top-0 left-0 w-full"
    v-if="this.$store.state.globalLoading"
  >
    <div class="flex flex-col justify-center items-center h-full">
      <vue-loaders-ball-scale color="#7676a5"></vue-loaders-ball-scale>
    </div>
  </div>
  <div v-if="showContent" class="">
    <Navbar />
    <router-view />
    <Footer />
    <CookieBanner />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import CookieBanner from "@/components/CookieBanner";
import { useCookies } from "vue3-cookies";

export default {
  name: "App",
  components: { Navbar, Footer, CookieBanner },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  computed: {
    showContent() {
      if (this.$route.fullPath != "/") {
        return true;
      }
      return false;
    },
  },
  mounted() {
    let thirdPartyCookiesEnabled = this.cookies.get("thirdPartyCookiesEnabled");
    if (thirdPartyCookiesEnabled && thirdPartyCookiesEnabled != null) {
      this.$store.commit("changeShowCookieBanner", false);
      if (thirdPartyCookiesEnabled === true) {
        this.$store.dispatch("setConsent", true);
      } else if (thirdPartyCookiesEnabled === false) {
        this.$store.dispatch("setConsent", false);
      }
    }
  },
};
</script>

<style></style>
