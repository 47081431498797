<!-- TODO: Fix footer info -->

<template>
  <footer class="p-10 footer bg-base-200 text-base-content footer-center">
    <div class="flex flex-col w-full">
      <router-link
        @click="goToStart"
        :to="{ name: 'Home', params: { locale: this.$i18n.locale } }"
      >
        <img class="h-10" :src="require('@/assets/FullLogo_B.svg')" alt="Logo"
      /></router-link>
      <p class="font-medium">
        {{ $t("footer.company_name") }} <br />
        {{ $t("footer.company_details") }} <br />
        <span v-if="this.$i18n.locale === 'de'">
          Telefonnummer: +41 91 986 30 55
          <br />
          Email:
          <a href="mailto:amministrazione@smsocialmanagement.com"
            >amministrazione@smsocialmanagement.com</a
          >
          <br />
          Gesetzlicher Vertreter: Lucia Rusi
        </span>
      </p>

      <div class="w-full">
        <div class="divider divider-dark"></div>
      </div>
      <p>
        QFindNow.com © 2022 - {{ $t("footer.rights") }}<br />
        <span class="hover:underline">
          <router-link
            @click="goToStart"
            :to="{ name: 'Privacy', params: { locale: this.$i18n.locale } }"
          >
            {{ $t("footer.cookiePolicy") }}
          </router-link></span
        >
        ·
        <span class="hover:underline"
          ><router-link
            @click="goToStart"
            :to="{ name: 'Privacy', params: { locale: this.$i18n.locale } }"
          >
            {{ $t("footer.privacy") }}
          </router-link></span
        ><br /><span
          class="hover:underline hover:cursor-pointer"
          @click="resetThirdPartyCookies"
        >
          {{ $t("footer.cookieSettings") }}</span
        >
        <br />
        <span class="hover:underline"
          ><router-link
            @click="goToStart"
            :to="{ name: 'Advertisers', params: { locale: this.$i18n.locale } }"
          >
            {{ $t("footer.for_advertisers") }}
          </router-link></span
        >
      </p>
    </div>
  </footer>
</template>

<script>
import { useCookies } from "vue3-cookies";

export default {
  name: "FooterComponent",
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  computed: {
    isHomeView() {
      if (this.$route.name === "Home") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    goToStart() {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },
    resetThirdPartyCookies() {
      let excluded = ["locale"];
      for (let key of this.cookies.keys()) {
        if (!excluded.includes(key)) {
          this.cookies.remove(key);
        }
        // console.log(this.cookies.keys());
      }

      this.$store.dispatch("setConsent", false);
      this.$store.commit("changeShowCookieBanner", true);
    },
  },
};
</script>
