export default {
  "common": {
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etsi jotain haluamaasi..."])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sivua ei löydy"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etsi jotain"])}
  },
  "footer": {
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting SAGL"])},
    "company_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Vedeggio 4 - 6928 Manno (Switzerland) - Partita IVA CHE- 309.820.548 IVA"])},
    "company_payoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luotettavaa tuotevertailua aina tarjonnut"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki oikeudet pidätetään"])},
    "cookiePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evästekäytäntö"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietosuojakäytäntö"])},
    "cookieSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evästeasetukset"])},
    "for_advertisers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])}
  },
  "search": {
    "sponsored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsoroitu sisältö"])},
    "related_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehdotetut haut"])},
    "ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mene"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tulokset:"])},
    "results_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["löydetyt tulokset"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["merenkulku"])},
    "freeshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmainen toimitus"])},
    "go_to_shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mene kauppaan"])},
    "try_searching_something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yritä etsiä jotain"])},
    "no_element_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementtejä ei löytynyt"])},
    "searchLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladataan..."])},
    "something_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jokin meni pieleen"])},
    "sort": {
      "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lajittelu:"])},
      "relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiaankuuluva"])},
      "priceAscending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinta nouseva"])},
      "priceDescending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinta laskeva"])}
    }
  },
  "cookie": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksy kaikki"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiellä kaikki"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tietosuojaselosteestamme"])},
    "bannerText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tämä verkkosivusto käyttää evästeitä, jotka auttavat verkkosivustoa toimimaan ja myös seuraamaan, miten olet vuorovaikutuksessa verkkosivustomme kanssa. Jotta voimme tarjota parhaan käyttäjäkokemuksen, hyväksy kaikki evästeet. Lisätietoja keräämistämme henkilötiedoista ja evästeiden käytöstä saat ", _interpolate(_named("privacyPolicy")), "."])}
  },
  "news": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "advertisers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])},
    "lastUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last Updated: ", _interpolate(_named("last_updated_date"))])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting"])},
    "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traffic to this site is generated through ", _interpolate(_named("company_name")), "'s proprietary technology, which allows us to place native ads with targeted keywords on multiple platforms such as Outbrain, Taboola, and others, which then lead to our various sites where search ads are served."])},
    "additionalInquiries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For any additional inquiries,\nEmail: ", _interpolate(_named("company_email"))])}
  }
}