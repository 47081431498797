<template>
  <!-- <ul>
    <li v-for="locale in locales" :key="locale" @click="switchLocale(locale)">
      {{ locale }}
    </li>
  </ul> -->

  <div class="dropdown dropdown-end dropdown-hover">
    <div
      tabindex="0"
      class="mx-1 md:m-1 btn btn-ghost btn-compact text-base shadow"
    >
      <img
        class="inline-block h-6 stroke-current md:mr-3"
        :src="getFlagImage(this.$i18n.locale)"
        alt=""
      />
      <div class="hidden md:block">
        <!-- {{ this.$i18n.locale }} -->
        {{ getCountryName(this.$i18n.locale) }}
      </div>
      <div class="hidden md:block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="ml-1 h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
    </div>

    <ul
      tabindex="0"
      class="
        shadow-md
        menu
        dropdown-content
        p-2
        bg-base-100
        rounded-box
        w-32
        md:w-52
        border-2
      "
    >
      <li
        class="font-medium font-display"
        v-for="locale in remainingLocales"
        :key="locale"
        @click="switchLocale(locale)"
      >
        <a>
          <img
            class="inline-block w-6 h-6 stroke-current mr-3"
            :src="getFlagImage(locale)"
            alt=""
          />
          <span class="uppercase">
            {{ getCountryName(locale) }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>


<script>
import { useCookies } from "vue3-cookies";
export default {
  name: "LocaleSwitcherComponent",
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        this.cookies.set("locale", locale, "90d");
        // const to = this.$router.resolve({ params: { locale } });
        this.$router.push({
          name: "Home",
          params: { locale },
        });

        window.scrollTo({ top: 0, left: 0 });
      }
    },
    getFlagImage(locale) {
      let flags = {
        default: "italy",
        be: "belgium",
        br: "brazil",
        de: "germany",
        dk: "denmark",
        es: "spain",
        fr: "france",
        fi: "finland",
        gr: "greece",
        it: "italy",
        nl: "netherlands",
        no: "norway",
        pl: "poland",
        pt: "portugal",
        ro: "romania",
        se: "sweden",
        uk: "united_kingdom",
        us: "united_states",
      };

      let flagname = flags[locale];

      if (!flagname) {
        flagname = flags["default"];
      }

      return require("@/assets/flags/" + flagname + ".svg");
    },
    getCountryName(locale) {
      let countries = {
        default: "it",
        be: "be",
        br: "br",
        de: "de",
        dk: "dk",
        es: "es",
        fr: "fr",
        fi: "fi",
        gr: "gr",
        it: "it",
        nl: "nl",
        no: "no",
        pl: "pl",
        pt: "pt",
        ro: "ro",
        se: "se",
        uk: "uk",
        us: "us",
      };

      let countryName = countries[locale];

      if (!countryName) {
        countryName = countries["default"];
      }

      return countryName;
    },
  },
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
    };
  },
  computed: {
    remainingLocales() {
      return this.locales.filter((locale) => locale !== this.$i18n.locale);
    },
  },
};
</script>