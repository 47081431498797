import { createStore } from "vuex";
// import router from '../router'
// import route from '../router'

export default createStore({
  state: {
    globalLoading: false,
    searchBarText: "",
    searchResultsText: "",
    searchLoading: false,
    products: {},
    sortMethod: "relevant",
    showCookieBanner: true,
    showNavbar: true,
    loadedTags: false,
  },
  mutations: {
    startGlobalLoading(state) {
      state.globalLoading = true;
    },
    stopGlobalLoading(state) {
      state.globalLoading = false;
    },
    changeSearchBarText(state, newText) {
      state.searchText = newText;
    },
    changeSearchResultsText(state, newText) {
      state.searchResultsText = newText;
    },
    startSearchLoading(state) {
      state.searchLoading = true;
    },
    stopSearchLoading(state) {
      state.searchLoading = false;
    },
    changeProducts(state, products) {
      state.products = products;
    },
    changeSortMethod(state, sortMethod) {
      state.sortMethod = sortMethod;
    },
    changeShowCookieBanner(state, showCookieBanner) {
      state.showCookieBanner = showCookieBanner;
    },
    changeShowNavbar(state, showNavbar) {
      state.showNavbar = showNavbar;
    },
    loadTags(state) {
      state.loadedTags = true;
    },
  },
  actions: {
    activateTags() {
      let metaPixelCode = document.createElement("script");
      metaPixelCode.innerHTML = ` 
        !function (f, b, e, v, n, t, s) {
          if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
              n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
          n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '916619386333665');
        fbq('track', 'PageView');`;
      document.head.appendChild(metaPixelCode);

      let metaNoSCript = document.createElement("noscript");
      metaNoSCript.innerHTML += `<img height='1' width='1' style='display:none'
      src='https://www.facebook.com/tr?id=916619386333665&ev=PageView&noscript=1' />`;
      document.head.appendChild(metaNoSCript);

      let gtag = document.createElement("script");
      gtag.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('consent', 'default', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied'
      });`;
      document.head.appendChild(gtag);

      let googleTagManager = document.createElement("script");
      googleTagManager.innerHTML = ` (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-MJCQ5H7');`;
      document.head.appendChild(googleTagManager);

      // let taboolaPixel = document.createElement("script");
      // taboolaPixel.innerHTML = `
      //   window._tfa = window._tfa || [];
      //   window._tfa.push({ notify: 'event', name: 'page_view', id: 1482438 });
      //   !function (t, f, a, x) {
      //     if (!document.getElementById(x)) {
      //       t.async = 1; t.src = a; t.id = x; f.parentNode.insertBefore(t, f);
      //     }
      //   }(document.createElement('script'),
      //     document.getElementsByTagName('script')[0],
      //     '//cdn.taboola.com/libtrc/unip/1482438/tfa.js',
      //     'tb_tfa_script');`;
      // document.head.appendChild(taboolaPixel);

      this.commit("loadTags");
    },
    setConsent(context, cookiesAccepted) {
      window.dataLayer = window.dataLayer || [];

      function gtag() {
        window.dataLayer.push(arguments);
      }

      let consentText;
      if (cookiesAccepted) {
        consentText = "granted";
      } else {
        consentText = "denied";
      }

      gtag("consent", "update", {
        ad_storage: consentText,
        analytics_storage: consentText,
      });
    },
    async searchProducts({ commit, state }, payload) {
      // const startingPath = route;
      // console.log(route.name)
      // var text = query.q;

      commit("changeSearchBarText", payload.query.q);
      commit("changeSearchResultsText", payload.query.q);
      commit("changeProducts", {});

      // let newquery = Object.assign({}, route.query);
      // console.log(newquery)
      // console.log(state)
      // console.log(router)
      // newquery = Object.assign(newquery, query);
      // for (let key in newquery) {
      //   if (!newquery[key]) {
      //     delete newquery[key];
      //   }
      // }
      // this.$router.replace({
      //   name: "Search",
      //   params: { lang: this.$i18n.locale },
      //   query: newquery,
      // });

      var data = {};
      if (!state.searchLoading) {
        commit("startSearchLoading");

        let newPayloadQuery = Object.assign({}, payload.query);

        if (newPayloadQuery.sortMethod) {
          if (newPayloadQuery.sortMethod == "relevant") {
            delete newPayloadQuery.sortBy;
            delete newPayloadQuery.sortDirection;
          } else if (newPayloadQuery.sortMethod == "priceAsc") {
            newPayloadQuery.sortBy = "price";
            newPayloadQuery.sortDirection = "asc";
          } else if (newPayloadQuery.sortMethod == "priceDesc") {
            newPayloadQuery.sortBy = "price";
            newPayloadQuery.sortDirection = "desc";
          }
          delete newPayloadQuery.sortMethod;
        }

        if (newPayloadQuery.adv) {
          delete newPayloadQuery.adv;
        }

        const url = new URL(process.env.VUE_APP_BACKEND_URL + "/search");

        for (const [key, value] of Object.entries(newPayloadQuery)) {
          url.searchParams.append(`${key}`, value);
        }
        url.searchParams.append("country", payload.locale);

        try {
          // const res = await fetch(url, { mode: 'no-cors' });
          const res = await fetch(url);
          // console.log(res)
          data = await res.json();
        } catch (error) {
          data = {};
        }

        commit("changeProducts", data);
        commit("stopSearchLoading");
      }

      // const currentPath = this.$route;
      // newquery = Object.assign(newquery, this.$route.query);
      // if (newquery.page > this.products.totPages) {
      //   newquery.page = this.products.totPages;
      // } else if (newquery.page < 1) {
      //   newquery.page = 1;
      // }
      // if (currentPath.path === startingPath.path) {
      //   this.$router.replace({
      //     name: "Search",
      //     params: { lang: this.$i18n.locale },
      //     query: newquery,
      //   });
      // }
    },
  },
  modules: {},
});
