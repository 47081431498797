import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import i18n from "./i18n";
import "vue-loaders/dist/vue-loaders.css";
import VueLoadersBallScale from "vue-loaders/dist/loaders/ball-scale";
import VueLoadersBallSpinFadeLoader from "vue-loaders/dist/loaders/ball-spin-fade-loader";
import store from "./store";
import { globalCookiesConfig } from "vue3-cookies";

globalCookiesConfig({
  expireTimes: "700d",
  secure: true,
});

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueLoadersBallScale)
  .use(VueLoadersBallSpinFadeLoader)
  .mount("#app");
