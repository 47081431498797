<template>
  <div
    v-if="this.$store.state.showCookieBanner"
    class="fixed bottom-4 left-0 right-0 z-30"
  >
    <div class="container mx-auto px-3">
      <div class="alert shadow-2xl bg-base-100 p-6">
        <!-- <div class="text-sm md:text-sm">
          This website uses cookies that help the website to function and also
          to track how you interact with our website. In order for us to provide
          the best user experience, please enable all cookies. To find out more
          about personal data we collect and how we use cookies please refer to
          our Privacy Policy.
        </div> -->

        <i18n-t
          keypath="cookie.bannerText"
          tag="p"
          class="block text-sm md:text-sm"
          scope="global"
        >
          <template #privacyPolicy>
            <span class="hover:underline font-medium">
              <router-link
                @click="goToStart"
                :to="{ name: 'Privacy', params: { locale: this.$i18n.locale } }"
              >
                {{ $t("cookie.privacyPolicy") }}
              </router-link>
            </span>
          </template>
        </i18n-t>
        <div class="flex-none">
          <button @click="denyCookie" class="btn btn-ghost">
            {{ $t("cookie.deny") }}
          </button>
          <button @click="acceptCookie" class="btn btn-primary">
            {{ $t("cookie.accept") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";

export default {
  name: "AdsContainerComponent",
  data() {
    return {};
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  methods: {
    acceptCookie() {
      this.cookies.set("thirdPartyCookiesEnabled", true);
      this.$store.dispatch("setConsent", true);
      this.hideBanner();
    },
    denyCookie() {
      this.cookies.set("thirdPartyCookiesEnabled", false);
      this.$store.dispatch("setConsent", false);
      this.hideBanner();
    },
    hideBanner() {
      this.$store.commit("changeShowCookieBanner", false);
    },

    goToStart() {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },
  },
};
</script>

<style></style>
