export default {
  "common": {
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök något du vill..."])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidan har inte hittats"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök något"])}
  },
  "footer": {
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting SAGL"])},
    "company_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Vedeggio 4 - 6928 Manno (Switzerland) - Partita IVA CHE- 309.820.548 IVA"])},
    "company_payoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi har alltid tillhandahållit tillförlitliga produktjämförelser"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla rättigheter förbehållna"])},
    "cookiePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie-policy"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integritetspolicy"])},
    "cookieSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie-inställningar"])},
    "for_advertisers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["För annonsörer"])}
  },
  "search": {
    "sponsored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsrat innehåll"])},
    "related_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Föreslagna sökningar"])},
    "ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultat för:"])},
    "results_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultat hittades"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frakt"])},
    "freeshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri frakt"])},
    "go_to_shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till butiken"])},
    "try_searching_something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök att söka något"])},
    "no_element_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga element hittades"])},
    "searchLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laddar..."])},
    "something_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Något gick fel"])},
    "sort": {
      "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortera efter:"])},
      "relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevant"])},
      "priceAscending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pris stigande"])},
      "priceDescending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pris på nedåtgående"])}
    }
  },
  "cookie": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptera alla"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förneka alla"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integritetspolicy"])},
    "bannerText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Den här webbplatsen använder cookies som hjälper webbplatsen att fungera och även för att spåra hur du interagerar med vår webbplats. För att vi ska kunna ge dig den bästa användarupplevelsen ber vi dig acceptera alla cookies. Om du vill veta mer om personuppgifter som vi samlar in och hur vi använder cookies hänvisar vi till vår ", _interpolate(_named("privacyPolicy")), "."])}
  },
  "news": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nästa"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbaka"])}
  },
  "advertisers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["För annonsörer"])},
    "lastUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Senast uppdaterad: ", _interpolate(_named("last_updated_date"))])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting"])},
    "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traffic till denna webbplats genereras av ", _interpolate(_named("company_name")), " egna teknologi, som gör att vi kan placera native annonser med riktade nyckelord på olika plattformar som Outbrain, Taboola och andra, vilket sedan leder till våra olika sidor där sökannonser visas."])},
    "additionalInquiries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["För ytterligare frågor,\nE-mail: ", _interpolate(_named("company_email"))])}
  }
}