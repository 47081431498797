export default {
  "common": {
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca qualcosa che ti piace..."])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina non trovata"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca qualcosa"])}
  },
  "footer": {
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting SAGL"])},
    "company_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Vedeggio 4 - 6928 Manno (Switzerland) - Partita IVA CHE- 309.820.548 IVA"])},
    "company_payoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre comparazioni affidabili di prodotti da sempre"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i diritti riservati"])},
    "cookiePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy dei Cookie"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy della Privacy"])},
    "cookieSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni dei Cookie"])},
    "for_advertisers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per Inserzionisti"])}
  },
  "search": {
    "sponsored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annunci per"])},
    "related_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerche suggerite"])},
    "ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultati per:"])},
    "results_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["risultati trovati"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di spedizione"])},
    "freeshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spedizione gratuita"])},
    "go_to_shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai al negozio"])},
    "try_searching_something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prova a cercare qualcosa"])},
    "no_element_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun elemento trovato"])},
    "searchLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento..."])},
    "something_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualcosa è andato storto"])},
    "sort": {
      "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina per:"])},
      "relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rilevanza"])},
      "priceAscending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo crescente"])},
      "priceDescending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo decrescente"])}
    }
  },
  "cookie": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta tutti"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiuta tutti"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy della Privacy"])},
    "bannerText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Questo sito usa i cookie per permettere al sito di funzionare e monitorare le tue interazioni con il sito. Per permetterci di fornirti la migliore esperienza utente possibile, accetta tutti i cookie. Per sapere di più riguardo ai dati personali che raccogliamo e come vengono usati i cookie controlla la nostra ", _interpolate(_named("privacyPolicy")), "."])}
  },
  "news": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanti"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])}
  },
  "advertisers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per Inserzionisti"])},
    "lastUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ultimo aggiornamento: ", _interpolate(_named("last_updated_date"))])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting"])},
    "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il traffico verso questo sito è generato grazie alla tecnologia proprietaria di ", _interpolate(_named("company_name")), ", che ci consente di posizionare annunci nativi con parole chiave mirate su più piattaforme come Outbrain, Taboola e altre, che portano ai nostri vari siti dove vengono mostrati annunci di ricerca."])},
    "additionalInquiries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Per informazioni aggiuntive,\nEmail: ", _interpolate(_named("company_email"))])}
  }
}