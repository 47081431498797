export default {
  "common": {
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter noe du vil ha..."])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siden ble ikke funnet"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter noe"])}
  },
  "footer": {
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting SAGL"])},
    "company_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Vedeggio 4 - 6928 Manno (Switzerland) - Partita IVA CHE- 309.820.548 IVA"])},
    "company_payoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gir pålitelig produktsammenligning siden alltid"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle rettigheter forbeholdt"])},
    "cookiePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retningslinjer for informasjonskapsler"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personvernerklæring"])},
    "cookieSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innstillinger for informasjonskapsler"])},
    "for_advertisers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])}
  },
  "search": {
    "sponsored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponset innhold"])},
    "related_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreslåtte søk"])},
    "ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultater for:"])},
    "results_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultater funnet"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shipping"])},
    "freeshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis frakt"])},
    "go_to_shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til butikk"])},
    "try_searching_something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv å søke etter noe"])},
    "no_element_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen elementer funnet"])},
    "searchLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laster..."])},
    "something_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noe gikk galt"])},
    "sort": {
      "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorter etter:"])},
      "relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevant"])},
      "priceAscending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pris stigende"])},
      "priceDescending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pris synkende"])}
    }
  },
  "cookie": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godta alle"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nekt alle"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personvernerklæring"])},
    "bannerText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dette nettstedet bruker informasjonskapsler som hjelper nettstedet til å fungere og også for å spore hvordan du samhandler med nettstedet vårt. For at vi skal kunne gi den beste brukeropplevelsen, vennligst godta alle informasjonskapsler. For å finne ut mer om personopplysninger vi samle inn og hvordan vi bruker informasjonskapsler, se vår ", _interpolate(_named("privacyPolicy")), "."])}
  },
  "news": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "advertisers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])},
    "lastUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last Updated: ", _interpolate(_named("last_updated_date"))])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting"])},
    "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traffic to this site is generated through ", _interpolate(_named("company_name")), "'s proprietary technology, which allows us to place native ads with targeted keywords on multiple platforms such as Outbrain, Taboola, and others, which then lead to our various sites where search ads are served."])},
    "additionalInquiries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For any additional inquiries,\nEmail: ", _interpolate(_named("company_email"))])}
  }
}