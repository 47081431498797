<template>
  <form class="form-control flex-grow" @submit.prevent="search()">
    <div class="relative">
      <input
        type="text"
        :placeholder="$t('common.search_placeholder')"
        v-model="searchBarText"
        class="
          min-w-full
          pr-16
          text-base-content
          input input-primary
          bg-gray-100
          rounded-full
          border-none
          md:text-lg md:pl-5
          justify-center
        "
      />
      <button
        class="absolute top-0 right-0 btn btn-primary btn-circle"
        type="submit"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: "SearchBarComponent",
  computed: {
    searchBarText: {
      get() {
        let text = this.$store.state.searchText;
        if (text == undefined) {
          return this.$route.query.q;
        }
        return text;
      },
      set(newText) {
        // console.log(newText);
        this.$store.commit("changeSearchBarText", newText);
      },
    },
  },
  mounted() {
    this.searchBarText = this.$store.state.searchText;
  },
  methods: {
    async search() {
      let locale = this.$i18n.locale;

      let query = { ...this.$route.query };
      query.q = this.$store.state.searchText;
      delete query.page;

      delete query.sortMethod;
      this.$store.commit("changeSortMethod", "relevant");

      // console.log(this.$route.query, query);
      for (let key in query) {
        if (!query[key]) {
          delete query[key];
        }
      }

      await this.$router.push({
        name: "Search",
        params: { locale: locale },
        query: query,
      });

      this.$router.go();

      if (!query.q) {
        // console.log("no q");
      } else {
        // console.log(document.title);
        // this.$store.dispatch("searchProducts", {
        //   query: query,
        //   locale: locale,
        // });
      }
    },
  },
};
</script>

<style>
</style>