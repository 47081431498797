import { createRouter, createWebHistory } from "vue-router";
import i18n from "../i18n";
import store from "../store";
import { useCookies } from "vue3-cookies";

// import Home from '../views/Home.vue'
// import Search from '../views/Search.vue'

function load(component) {
  // '@' is aliased to src/components
  return () => import(`@/views/${component}.vue`);
}

const routes = [
  {
    path: "/:locale",
    component: {
      template: "<router-view></router-view>",
    },

    beforeEnter: (to, from, next) => {
      store.commit("startGlobalLoading");
      const locale = to.params.locale;

      var pathname = "";

      var fullPath = to.fullPath;
      var pathArray = fullPath.split("/");
      if (pathArray.length > 2) {
        for (let i = 2; i < pathArray.length; i++) {
          pathname += "/";
          pathname += pathArray[i];
        }
      }

      const supported_locales =
        process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");

      if (i18n.global.locale.value !== locale) {
        i18n.global.locale.value = locale;
      }

      const { cookies } = useCookies();
      if (cookies.isKey("locale")) {
        let prefLocale = cookies.get("locale");

        if (to.query.forceLocale) {
          prefLocale = i18n.global.locale.value;
          // console.log('ciao')
        }

        // console.log(prefLocale, locale)
        if (supported_locales.includes(prefLocale)) {
          if (i18n.global.locale.value != prefLocale) {
            return next("/" + prefLocale + pathname);
          } else {
            i18n.global.locale.value = prefLocale;
            cookies.set("locale", prefLocale, "90d");
            store.commit("stopGlobalLoading");
            return next();
          }
        }
      }

      if (!supported_locales.includes(locale)) {
        return next("/" + process.env.VUE_APP_I18N_FALLBACK_LOCALE + pathname);
      }

      async function fetchIP({ skiptrying }) {
        // let cloudFlareUrl = new URL('https://www.cloudflare.com/cdn-cgi/trace')
        // let cloudFlareUrl = new URL('http://ip-api.com/json/')
        // let cloudFlareUrl = new URL('http://geolocation-db.com/json/')
        // let response = await fetch(cloudFlareUrl, { mode: 'cors' }).catch((reason) => { console.log(reason) })
        // console.log(response)

        // let text = await response.text()
        // let ip = text.substring(text.search('ip=') + 3, text.search('ts='));

        // // let url = 'https://api.iplocation.net/?ip=' + ip
        // const url = new URL(process.env.VUE_APP_BACKEND_URL + '/get-location');
        // url.searchParams.append("ip", ip);

        // response = await fetch(url).catch((reason) => { console.log(reason) })
        // let locationData = await response.json()

        let data = {};
        if (!skiptrying) {
          let url = new URL("https://geolocation-db.com/json/");
          let response = await fetch(url, { mode: "cors" }).catch((reason) => {
            console.log(reason);
          });

          try {
            data = await response.json();
          } catch (e) {
            console.log(e);
          }

          try {
            if (!data.country_code) {
              // console.log(data)

              let ip = data.IPv4;
              const url = new URL(
                process.env.VUE_APP_BACKEND_URL + "/get-location"
              );
              url.searchParams.append("ip", ip);

              response = await fetch(url).catch((reason) => {
                console.log(reason);
              });
              data = await response.json();
            }
          } catch (error) {
            console.log(error);
          }
        }

        return data;
      }
      fetchIP({ skiptrying: true })
        .then(function (data) {
          let country_code =
            data && data.country_code
              ? data.country_code.toLowerCase()
              : locale;

          if (to.query.forceLocale) {
            country_code = i18n.global.locale.value;
          }

          if (supported_locales.includes(country_code)) {
            if (i18n.global.locale.value != country_code) {
              i18n.global.locale.value = country_code;
              // console.log(country_code + pathname)

              return next("/" + country_code + pathname);
            } else {
              cookies.set("locale", country_code, "90d");
              store.commit("stopGlobalLoading");
              // console.log(to)
              return next();
            }
          } else {
            if (locale === process.env.VUE_APP_I18N_FALLBACK_LOCALE) {
              store.commit("stopGlobalLoading");
              return next();
            } else {
              return next(
                "/" + process.env.VUE_APP_I18N_FALLBACK_LOCALE + pathname
              );
            }
          }
          // store.commit('stopGlobalLoading')
          // return next()
        })
        .catch(function (reason) {
          console.log(reason);
          store.commit("stopGlobalLoading");
          return next();
        });
    },
    children: [
      {
        path: "",
        name: "Home",
        component: load("Home"),
      },
      {
        path: "search",
        name: "Search",
        //   // route level code-splitting
        //   // this generates a separate chunk (about.[hash].js) for this route
        //   // which is lazy-loaded when the route is visited.
        //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
        component: load("Search"),
      },
      {
        path: "privacy",
        name: "Privacy",
        component: load("Privacy"),
      },
      {
        path: "redirect",
        name: "Redirect",
        component: load("Redirect"),
      },
      {
        path: "trending",
        name: "Trending",
        component: load("Trending"),
      },
      {
        path: "4722175671/:arid",
        name: "Stories",
        component: load("Stories"),
        // alias: "966389429958/:arid",
      },
      {
        path: "advertisers",
        name: "Advertisers",
        component: load("Advertisers"),
      },
      {
        // path: ':pathMatch(.+)*',
        // path: ":notvalid",
        path: ":pathMatch(.+)+",
        name: "PageNotFound",
        component: load("PageNotFound"),
      },
    ],
  },
  {
    path: "/",
    redirect: i18n.global.locale.value,
  },
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'PageNotFound',
  //   component: load('PageNotFound')
  // }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // document.title = `${Math.random()}`
  if (to.name == "Search" && to.query.q) {
    document.title = to.query.q + " - QFindNow";
  } else {
    document.title = "QFindNow";
  }

  next();
});

router.afterEach((to) => {
  if (!store.state.loadedTags) {
    if (to.name != "Stories") {
      store.dispatch("activateTags");
    }
  }
});

export default router;
