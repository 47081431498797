<template>
  <nav
    class="navbar py-2 bg-base-100 top-0 w-full rounded-b-box z-40"
    :class="[isHomeView ? ['absolute'] : ['fixed', 'shadow-md']]"
    v-if="this.$store.state.showNavbar"
  >
    <div class="flex-shrink-0 ml-4 lg:ml-6">
      <router-link
        @click="goToStart"
        :to="{ name: 'Home', params: { locale: this.$i18n.locale } }"
        ><img
          class="h-10"
          :class="[isHomeView ? ['block'] : ['hidden', 'md:block']]"
          :src="require('@/assets/FullLogo_Color.svg')"
          alt="Logo" />

        <img
          class="mr-4 h-10"
          :class="[isHomeView ? ['hidden'] : ['md:hidden', 'block']]"
          :src="require('@/assets/Logo_Color.svg')"
          alt="Logo"
      /></router-link>
    </div>

    <div
      class="
        flex-grow
        justify-center
        mr-4
        md:mx-8
        lg:mx-16
        xl:mx-40
        transition-all
      "
    >
      <SearchBar v-if="!isHomeView" />
    </div>

    <div class="md:flex" :class="[!isHomeView ? 'hidden' : '']">
      <LocaleSwitcher />
    </div>
  </nav>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher";
import SearchBar from "@/components/SearchBar";

export default {
  name: "NavbarComponent",
  components: {
    LocaleSwitcher,
    SearchBar,
  },
  computed: {
    isHomeView() {
      if (this.$route.name === "Home") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    goToStart() {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },
  },
};
</script>
